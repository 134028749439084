/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import Footer from "./cybernate/footer"
import "../../node_modules/bootstrap/dist/css/bootstrap.css"
import "../../node_modules/animate.css/animate.css"
import "../css/layout.css"




class Layout extends React.Component {

    render() {

        const Header = this.props.header;
        
        return (
            <StaticQuery
                query={graphql`
                    query SiteTitleQuery {
                        site {
                            siteMetadata {
                                title
                            }
                        }
                    }
                `}
                render={data => (
                    <div className="bodyWrapper">
                        <div>
                            {Header != null ? (<Header />) : null}
                            <main>{this.props.children}</main>
                        </div>
                        <Footer className={"".concat((this.props.footerClassName) ? this.props.footerClassName : "") } />
                    </div>
                )}
            />
        )
    }
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
