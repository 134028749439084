import React from "react"
import { Link } from "gatsby"
import logoFooter from "../../images/logo-footer.png"

const Footer = (props) => (
    <div className={"footer ".concat(props.className)}>
        <Link to="/">
            <img src={logoFooter} alt="Cybernate Logo" />
        </Link>
        <span className="mt-1 copyright">
            &copy; Cybernate. All Rights Reserved
        </span>
    </div>
)

export default Footer
